import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {Router} from '@angular/router';
import {SideNavService} from '../../services/sidenav.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  error;
  selectedMenu;

  constructor(protected router: Router, private sideNavService: SideNavService) {
  }

  ngOnInit() {
    this.selectedMenu = this.router.url.split('/')[1];
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }
}
