import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dialog, Grid,DialogTitle, IconButton, Divider, DialogContent, Typography,Accordion, AccordionSummary, Checkbox, AccordionDetails, Tooltip } from '@material-ui/core';
import {createStyles,Theme,makeStyles, withStyles} from '@material-ui/core/styles';
import { CloseIcon } from 'src/components/privilages/pop-up-window/PopUp';
import { addButtonColor, mainAccordingColor, textColor } from 'src/components/sub-components/colors/colors';
import { TextBar } from 'src/components/sub-components/TextBar';
import { UserRoleAccordingList } from './according-list/UserRoleAccordingList';
import { environment } from 'src/environments/environment';


const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    inputGridsLeft:{
        margin:'5px',
    },
    text:{
        marginTop:"10px",
        marginBottom:"5px",
        color:textColor
    },
    tableHeader:{
        backgroundColor:"#7C8E96",
        color:"black",
        marginTop:"15px",
        paddingTop:"10px",
        paddingBottom:"10px"
    },
    add:{
        backgroundColor:addButtonColor,
        color:"black",
        marginBottom:"10px",
        marginRight:"10px",
        width:"20%",
        boxShadow:"none",
        "&:hover":{
            boxShadow:"none",
        }
    },
    cancel:{
        backgroundColor:"white",
        color:addButtonColor,
        marginBottom:"10px",
        marginRight:"10px",
        width:"20%",
        boxShadow:"none",
        "&:hover":{
            boxShadow:"none",
        }
    },
    highPrivilegeAccording:{
        backgroundColor:mainAccordingColor,
        width:"100%",
        marginBottom:"1px",
    },
  })
)
export function UserRolePopUp(props) {
    const classes = useStyles();

    const [userPrivilege,setUserPrivilege] = useState([])

    useEffect(()=>{
        if(props.userRoleId!==undefined){
            fetch(`${environment.host}/ums/super/user-privileges/${props.userRoleId}`,
            {
                method:"GET",
                headers:{ 'Content-Type': 'application/json' }
            }).then(response => response.json())
            .then(data =>{
                if(data.message==="Success"){
                    setUserPrivilege(data.content);
                }
                else{
                    alert(data.message)
                }
            })
        }
    },[props.open])
    return (
        <Dialog open={props.open} maxWidth="md">
            <Grid container>
                <Grid item xs={10} md={10}>
                    <DialogTitle>View User Privilege Details</DialogTitle>
                </Grid>
                <Grid item xs={2} md={2} style={{textAlign:"right"}}>
                    <IconButton onClick={()=>{
                        props.onClose() }}>
                        <CloseIcon/>
                    </IconButton>
                </Grid>
            </Grid>

            <Divider/>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={5} className={classes.inputGridsLeft}>
                        <Typography className={classes.text}>Role ID</Typography>
                        <TextBar defaultValue={props.userRoleId} width="100%" type="text" error={{error:false, errorMsg:""}}/>
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.inputGridsLeft}>
                        <Typography className={classes.text}>Role Name</Typography>
                        <TextBar defaultValue={props.userName} width="100%" type="text" error={{error:false, errorMsg:""}}/>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.inputGridsLeft}>
                        <Typography className={classes.text}>Role Description</Typography>
                        <TextBar defaultValue={props.description} width="100%" type="text" error={{error:false, errorMsg:""}}/>
                    </Grid>

                    <Grid container className={classes.tableHeader}>
                        <Grid item xs={6} md={6} style={{textAlign:"center"}}>
                            <Typography style={{fontWeight:"bold"}}>SERVICES</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{textAlign:"center"}}>
                            <Typography style={{fontWeight:"bold"}}>ACTIONS</Typography>
                        </Grid>
                    </Grid>

                    <Grid container>
                        {
                            userPrivilege.map((privilege,index)=>{
                                return(
                                    <CustomAccordion key={index} elevation={0} TransitionProps={{ unmountOnExit: true }}  className={classes.highPrivilegeAccording}>
                                        <AccordionSummary>
                                            <Grid container>
                                                <Grid item xs={1} md={1}>
                                                    <CheckBoxIcon/>
                                                </Grid>
                                                <Grid item xs={3} md={3}>
                                                    <Tooltip title={privilege.name} placement="bottom-start" arrow>
                                                        <Typography>{privilege.name.length>15?privilege.name.substring(0,15)+"...":privilege.name}</Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1} md={1}></Grid>
                                                {
                                                    privilege.actions.map((action, i)=>{
                                                        return(
                                                            <Grid item xs={1} md={1} key={i} style={{marginLeft:"10px"}}>
                                                                <Typography style={{color:"#4C4F50"}}>{action.action_name}</Typography>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails style={{backgroundColor:"white", paddingBottom:"0px",padding:"0px"}}>
                                            <UserRoleAccordingList data={privilege.child}  opacity={0.25} padding={1}/>
                                        </AccordionDetails>
                                    </CustomAccordion>
                                )
                            })
                        }
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const CustomAccordion = withStyles({
    root: {
        "&$expanded": {
        margin: "auto"
        }
    },
    expanded: {}
})(Accordion);

export const CheckBoxIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="rgba(24, 29, 51, 0.64)"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"/></svg>
    )
}