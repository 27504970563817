import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../services/sidenav.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MainService} from '../services/main.service';
import {ToastrService} from 'ngx-toastr';
import {Privilege} from '../models';
import {toggleHideById} from '../utils';
import {FormBuilder, Validators} from '@angular/forms';
import {FORM_TYPE} from '../enums';

@Component({
  selector: 'app-privileges',
  templateUrl: './privileges.component.html',
  styleUrls: ['./privileges.component.scss']
})
export class PrivilegesComponent implements OnInit {
  // imported methods reassigned for view
  toggleHideById = toggleHideById;

  p=1;
  privileges: Privilege[] = [];
  form;
  isSubmitted = false;
  formType;
  selectedPrivilegeId;
  selectedPrivilegeName;
  error;

  constructor(private sideNaveService: SideNavService, private spinner: NgxSpinnerService,
              private mainService: MainService, private toaster: ToastrService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getPrivileges();
    this.form = this.formBuilder.group({
      id: [null, [Validators.required]],
      name: [null, [Validators.required]],
      description: ['', []]
    });
  }

  isSideNavVisible() {
    return this.sideNaveService.isSideNavVisible;
  }

  getPrivileges() {
    this.spinner.show();
    this.mainService.getPrivileges()
      .subscribe(response => {
        this.privileges = response.content;
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  deletePrivilege() {
    this.spinner.show();
    this.mainService.deleteUserPrivilege(this.selectedPrivilegeId)
      .subscribe(response => {
        this.toaster.success('Successfully Deleted!');
        this.spinner.hide();
      }, error => {
        this.toaster.error('Something Went Wrong!');
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
        toggleHideById('deletePrivilege');
        this.getPrivileges();
      });
  }

  addPrivilegeInit() {
    this.formType = FORM_TYPE.ADD;
    this.isSubmitted = false;
    this.form.reset();
    this.error = null;
    toggleHideById('addNew');
  }

  editPrivilegeInit(privilege) {
    this.formType = FORM_TYPE.EDIT;
    this.isSubmitted = false;
    this.form.reset();
    this.form.patchValue(privilege);
    this.error = null;
    toggleHideById('addNew');
  }

  createPrivilege(value) {
    this.isSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    if (this.formType === FORM_TYPE.ADD) {
      this.mainService.createUserPrivilege(value).subscribe(response => {
        this.spinner.hide();
        toggleHideById('addNew');
        this.toaster.success('Privilege Created Successfully!');
      }, error => {
        this.error = error.error.message;
        this.spinner.hide();
        this.toaster.error('Something Went Wrong!');
      }, () => {
        this.getPrivileges();
        this.spinner.hide();
      });
    } else {
      this.mainService.updateUserPrivilege(value).subscribe(response => {
        this.spinner.hide();
        toggleHideById('addNew');
        this.toaster.success('Privilege Updated Successfully!');
      }, error => {
        this.error = error.error.message;
        this.toaster.error('Something Went Wrong!');
        this.spinner.hide();
      }, () => {
        this.getPrivileges();
        this.spinner.hide();
      });
    }
  }
}
