import * as React from 'react';
import { useState,useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, Tooltip, Typography} from '@material-ui/core';
import {createStyles,Theme,makeStyles} from '@material-ui/core/styles';
import { SearchBar } from '../sub-components/SearchBar';
import { addButtonColor, mainAccordingColor} from '../sub-components/colors/colors';
import { PopUp } from './pop-up-window/PopUp';
import { AccordingList, AddIcon, DeleteIcon, EditIcon, ExpandIcon } from './according-list/AccordingList';
import { environment } from 'src/environments/environment';
import { AlertBar } from '../sub-components/AlertBar';

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    addHighPrivileges:{
        backgroundColor:addButtonColor,
        color:"black",
        marginLeft:"10px",
        marginRight:"10px",
        textTransform:"none",
    },
    tableHeader:{
        backgroundColor:"#7C8E96",
        color:"black",
        marginLeft:"15px",
        marginRight:"15px",
        marginTop:"15px",
        paddingTop:"10px",
        paddingBottom:"10px"
    },
    privilageContainer:{
        margin:"15px"
    },
    highPrivilegeAccording:{
        backgroundColor:mainAccordingColor,
        marginBottom:"1px"
    },
    iconButtonDiv:{
        backgroundColor:"white",
        width:"25px",
        height:"25px"
    },
    addHighPrivilegesAccording:{
        backgroundColor:"white",
        color:"black",
        marginLeft:"10px",
        marginRight:"10px",
        textTransform:"none",
        marginTop:"4px"
    },
    AccordionSummary:{
        height:"55px",
        [themes.breakpoints.down('xs')]:{
            height:"150px",
        },
        [themes.breakpoints.between('xs','md')]:{
            height:"200px",
        }
    }
  }),
);

export function PrivilagesComponent(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [popUpType,setPopUpType]=useState();
    const [parentId,setParentId]=useState();
    const [defaultValues,setDefultValues]=useState({name:"",id:"",description:"",actions:[]})
    const [content,setContent] = useState([])
    const [search,setSearch]=useState("")
    const [hover,setHover]=React.useState(-1)

    //alert states
    const [alertOpen,setAlertOpen]=useState(false)
    const [severity,setSeverity]=useState("success")
    const [topic,setTopic]=useState("")
    const [message,setMessage]=useState("")

    const onHover=(id)=>{
        setHover(id)
    }
    const removeHover=()=>{
        setHover(-1)
    }

    const openAddNew=(e,i,type,defaultVal)=>{
        e.stopPropagation();
        setPopUpType(type)
        setParentId(i)
        setDefultValues(defaultVal)
        setOpen(!open)
    }

    const getPrivileges = ()=>{
        fetch(`${environment.host}/ums/super/user-privileges`,
        {
            method:"GET",
            headers:{ 'Content-Type': 'application/json' }
        }).then(response => response.json())
        .then(data =>{
            if(data.message==="Success"){
                setContent(data.content);
            }
            else{
                setAlertOpen(true)
                setSeverity("error")
                setTopic("Error")
                setMessage(data.message)
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
            }
        })
    }

    const deletePrivilege=(id)=>{
        fetch(`${environment.host}/ums/super/user-privilege/${id}`,
        {
            method:"DELETE",
            headers:{ 'Content-Type': 'application/json' }
        }).then(response => response.json())
        .then(data =>{
            if(data.message==="Success"){
                getPrivileges()
                setAlertOpen(true)
                setSeverity("success")
                setTopic("Success")
                setMessage("Privilege deleted successfully")
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
            }
            else{
                setAlertOpen(true)
                setSeverity("error")
                setTopic("Error")
                setMessage(data.message)
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
            }
        })
    }
    const closeAddNew=()=>{
        setOpen(false)
        getPrivileges()
    }

    useEffect(()=>{
        getPrivileges()
    },[])

    return (
        <div className={`main-react-view`}>
            <Grid container>
                <Grid item xs={6} md={4}>
                   <SearchBar onChange={(e)=>setSearch(e.target.value)}/>
                </Grid>
                <Grid item  md={5}></Grid>
                <Grid item xs={6} md={3}>
                    <Button variant='contained' onClick={(e)=>openAddNew(e,undefined,"add-high",undefined)} className={classes.addHighPrivileges}>Add High Privilege</Button>
                </Grid>
                <Grid container className={classes.tableHeader}>
                    <Grid item xs={2} md={2}>
                        <Typography style={{fontWeight:"bold"}}>Privileges</Typography>
                    </Grid>
                    <Grid item xs={2} md={2} style={{paddingLeft:"20px"}}>
                        <Typography style={{fontWeight:"bold"}}>Id</Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Typography style={{fontWeight:"bold"}}>Description</Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Typography style={{fontWeight:"bold"}}>Action</Typography>
                    </Grid>
                </Grid>

                {
                    content.length>0 ?
                        <Grid container className={classes.privilageContainer}>
                            <Grid item xs={12} md={12}>

                                {
                                    content.filter((obj)=>{
                                        if(search===""){
                                            return obj
                                        }else if(obj.name.toLowerCase().includes(search.trim().toLowerCase())){
                                            return obj
                                        }
                                    }).map((parent,index)=>{
                                        return(
                                            <div key={index}>
                                            <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}  className={classes.highPrivilegeAccording}>
                                                <AccordionSummary className={classes.AccordionSummary} expandIcon={<div className={classes.iconButtonDiv}><ExpandIcon/></div>}>
                                                    <Grid container>
                                                        <Grid item xs={2} md={2} style={{textAlign:"left"}}>
                                                            <Tooltip title={parent.name} placement="bottom-start" arrow>
                                                                <Typography style={{marginTop:"12px", fontWeight:"bold"}}>{parent.name.length>15?parent.name.substring(0,15)+"...":parent.name}</Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={1} md={1}></Grid>
                                                        <Grid item xs={2} md={2} style={{textAlign:"left",paddingLeft:"9px"}}>
                                                            <Tooltip title={parent.id.toString()} placement="bottom-start" arrow>
                                                                <Typography style={{marginTop:"12px"}}>{parent.id.toString().length>6?parent.id.toString().substring(0,6)+"...":parent.id}</Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={4} md={4} style={{textAlign:"left",paddingLeft:"15px"}}>
                                                        <Tooltip title={parent.description} placement="bottom-start" arrow>
                                                            <Typography style={{marginTop:"12px"}}>{parent.description!==undefined && parent.description.length>40?parent.description.substring(0,40)+"...":parent.description}</Typography>
                                                        </Tooltip>
                                                        </Grid>
                                                        
                                                        <Grid item xs={3} md={3} style={{marginTop:"5px",textAlign:"right"}}>
                                                            <IconButton onClick={(e)=>openAddNew(e,"high-privilege","update",{name:parent.name,id:parent.id,description:parent.description,actions:parent.actions})}><EditIcon/></IconButton>      
                                                            <IconButton onClick={()=>deletePrivilege(parent.id)}><DeleteIcon/></IconButton>
                                                            <Button 
                                                                onClick={(e)=>openAddNew(e,parent.id,"add-new",undefined)} 
                                                                className={classes.addHighPrivilegesAccording}
                                                                onMouseEnter={()=>onHover(parent.id)}
                                                                onMouseLeave={()=>removeHover()}

                                                                style={{paddingLeft:hover===parent.id?"10px":"0px",transition:"0.6s",backgroundColor:hover===parent.id?"white":"white", minWidth:"24px",height:"24px"}}
                                                                endIcon={<AddIcon/>}
                                                                >{hover===parent.id?"Add privilege":""}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                
                                                <AccordionDetails style={{backgroundColor:"white", padding:"0px"}}>
                                                    <AccordingList onDelete={(id)=>deletePrivilege(id)} onOpen={(e,i,type,defaultVal)=>openAddNew(e,i,type,defaultVal)} data={parent.child} opacity={0.3} padding={1}/>
                                                </AccordionDetails>
                                            </Accordion>
                                            </div>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    :
                    <Grid container style={{textAlign:"center"}}>
                        <Grid item xs={12} md={12} className={'null-content'}>
                            <Typography>No privilleges Found</Typography>
                        </Grid>
                    </Grid>
                }
                
                
                <PopUp open={open} onClose={closeAddNew} type={popUpType} parentId={parentId} defaultValues={defaultValues}/>
                
            </Grid>
            <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
        </div>
    )
}