import * as React from 'react';
import { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import {createStyles,Theme,makeStyles, withStyles} from '@material-ui/core/styles';
import { TextBar } from 'src/components/sub-components/TextBar';
import { SearchBar } from 'src/components/sub-components/SearchBar';
import { addButtonColor, mainAccordingColor } from 'src/components/sub-components/colors/colors';
import { AddNewRoleAccordingList } from './according-list/AddNewRoleAccordingList';
import { environment } from 'src/environments/environment';
import { AlertBar } from 'src/components/sub-components/AlertBar';

export function AddNewRole(props) {
    const classes = useStyles()
    const[hidden, setHidden] = useState(false)
    const [allPrivileges,setAllPrivileges] = useState([])
    const [allActions,setAllActions]=useState([])
    const [currentPrivileges,setCurrentPrivileges] = useState([])
    const [search,setSearch]=useState("")

    const [idError, setIdError] = useState({error:false, errorMsg:""})
    const [nameError,setNameError] = useState({error:false, errorMsg:""})

    const [selectedName,setSelectedName]=useState()
    const [selectedId,setSelectedId]=useState()
    const [selectedDescription,setSelectedDescription]=useState()

    //alert states
    const [alertOpen,setAlertOpen]=useState(false)
    const [severity,setSeverity]=useState("success")
    const [topic,setTopic]=useState("")
    const [message,setMessage]=useState("")

    //getAll privileges function
    const getPrivileges = ()=>{
        fetch(`${environment.host}/ums/super/user-privileges`,
        {
            method:"GET",
            headers:{ 'Content-Type': 'application/json' }
        }).then(response => response.json())
        .then(data =>{
            if(data.message==="Success"){

                let actions=[]
                data.content.map((privilege)=>{
                    privilege.actions.map((action)=>{
                        if(!actions.includes(action.action_name)){
                            actions.push(action.action_name)
                        }
                    })
                    pushAllActions(privilege.child,actions)
                })
                setAllActions(actions)
                setAllPrivileges(data.content);
            }
            else{
                setAlertOpen(true)
                setSeverity("error")
                setTopic("Error")
                setMessage(data.message)
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
            }
        })
    } 
    //set All actions function
    const pushAllActions=(privilages,array)=>{
        privilages.map((privi)=>{
            privi.actions.map((action)=>{
                if(!array.includes(action.action_name)){
                    array.push(action.action_name)
                }
            })
            pushAllActions(privi.child,array)
        })
    }

    let ids=[]
    const idFinder=(id,allPrivileges)=>{
        allPrivileges.map((p)=>{
            idFinder(id,p.child)
            if(id==p.id){
                p.childIds.map((i)=>{
                    ids.push(i)
                    idFinder(i,p.child)
                })
            }
        })
    }
    const selectPrivileges=(id)=>{
        idFinder(id,allPrivileges)
        let oldPrivileges=[...currentPrivileges]
        if(oldPrivileges.filter(p=>p.id===id).length>0){
            oldPrivileges=oldPrivileges.filter(p=>!(p.id===id))
            ids.map((i)=>{
                oldPrivileges=oldPrivileges.filter(p=>!(p.id===i))
            })
        }
        else{
            oldPrivileges.push({"id":id})
        }
        console.log(oldPrivileges)
        setCurrentPrivileges(oldPrivileges);
    }

    let allPrivilegeIds=[]
    const selectAllPrivileges=(allPrivileges)=>{
        allPrivileges.map((privilege)=>{
            allPrivilegeIds.push({"id":privilege.id})
            selectAllPrivileges(privilege.child)
        })
        console.log(allPrivilegeIds)
        setCurrentPrivileges(allPrivilegeIds)
    }

    //Function to read pivilege name
    const readName=(e)=>{
        setSelectedName(e.target.value)
        setNameError({error:false, errorMsg:""})
    }
    //Funcion to read new privilege id
    const readId=(e)=>{
        setSelectedId(e.target.value)
        setIdError({error:false, errorMsg:""})
    }
    //Function to read description
    const readDescription=(e)=>{
        setSelectedDescription(e.target.value)
    }

    //Submit data
    const submit=()=>{
        const data={
            id:selectedId,
            name:selectedName,
            description:selectedDescription,
            userPrivileges:currentPrivileges
        }
        if(props.type==="edits"){
           if(data.name===undefined || data.name===""){
                data.name=props.userName
            }
            if(data.id===undefined || data.id===""){
                data.id=props.userRoleId
            }
            if(data.description===undefined || data.description===""){
                data.description=props.description
            }
            apiRequest(data,"PUT")
        }
        if(props.type==="new-role"){
            if(data.id===undefined || data.id===""){
                setIdError({error:true, errorMsg:"Id is required"})
                return
            }
            if(data.name===undefined || data.name===""){
                setNameError({error:true, errorMsg:"Name is required"})
                return
            }
            apiRequest(data,"POST")
        }
    }

    const apiRequest=(data,method)=>{
        let url=`${environment.host}/ums/super/user-role`
        if(method==="PUT"){
            url=url+"/"+props.userRoleId //When changing the id request parameter id should be the old id
        }
        fetch(url,
            {
                method:method,
                headers:{ 'Content-Type': 'application/json' },
                body:JSON.stringify(data)
            }).then(response => response.json())
            .then(data =>{
              if(data.message==="Success"){
                props.onClose()
              }
              else{
                setAlertOpen(true)
                setSeverity("error")
                setTopic("Error")
                setMessage(data.message)
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
              }
            })
    }

    useEffect(()=>{
        getPrivileges()
        if(props.type==="edits"){
            fetch(`${environment.host}/ums/super/user-role/${props.userRoleId}`,
            {
                method:"GET",
                headers:{ 'Content-Type': 'application/json' }
            }).then(response => response.json())
            .then(data =>{
                if(data.message==="Success"){
                    setCurrentPrivileges(data.content.userPrivileges)
                }
                else{
                    setAlertOpen(true)
                    setSeverity("error")
                    setTopic("Error")
                    setMessage(data.message)
                    setTimeout(()=>{
                        setAlertOpen(false)
                    },6000) 
                }
            })
        }
    },[])

    return (
        <div>
            <Grid container style={{textAlign:"left"}}>
                <Grid item xs={1} md={1} style={{textAlign:"right"}}>
                    <IconButton onClick={()=>props.onClose()}>
                        <BackIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={5} md={5} style={{textAlign:"left", marginTop:"12px"}}>
                    <Typography className={classes.addNew}>{props.type==="edits"?"Update Role":"Add New Role"}</Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Paper className={classes.paperContainer}>
                        <Grid container>
                            <Grid item xs={12} md={4} style={{padding:"15px"}}>
                                <Typography className={classes.text}>Enter ID</Typography>
                                <TextBar onChange={(e)=>readId(e)} defaultValue={props.userRoleId} type="number" error={idError} width="90%"/>
                            </Grid>
                            <Grid item xs={12} md={4} style={{padding:"15px"}}>
                                <Typography className={classes.text}>Enter Role Name</Typography>
                                <TextBar onChange={(e)=>readName(e)} defaultValue={props.userName} type="text" error={nameError} width="90%"/>
                            </Grid>
                            <Grid item xs={12} md={4} style={{padding:"15px"}}>
                                <Typography className={classes.text}>Enter Description</Typography>
                                <TextBar onChange={(e)=>readDescription(e)} defaultValue={props.description} type="text" error={{error:false}} width="90%"/>
                            </Grid>
                            <Grid item xs={11} md={1} style={{padding:"15px"}}>
                                <Typography className={classes.text}>Privileges</Typography>
                            </Grid>
                            <Grid item md={9} style={{marginTop:"27px",marginLeft:"10px"}}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton onClick={()=>setHidden(!hidden)} style={{backgroundColor:"#F5F5F5",marginLeft:"5px"}}>
                                    <ExpandIcon/>
                                </IconButton>
                            </Grid>

                            {
                                hidden===false?
                                <>
                                    <Grid item xs={12} md={4} style={{padding:"15px"}}>
                                        <SearchBar onChange={(e)=>setSearch(e.target.value)}/>
                                    </Grid>

                                    <Grid container className={classes.tableHeader}>
                                        <Grid item xs={6} md={4} style={{textAlign:"center"}}>
                                            <Typography style={{fontWeight:"bold"}}>SERVICES</Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8} style={{textAlign:"center"}}>
                                            <Typography style={{fontWeight:"bold"}}>ACTIONS</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.actionsBar}>
                                        <Grid item xs={6} md={3}></Grid>
                                        {
                                            allActions.map((action,index)=>{
                                                return(
                                                    <Grid item xs={1} md={1} key={index}>
                                                        <Typography>{action}</Typography>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid container style={{marginTop:"10px",marginBottom:"10px"}}>
                                        <Grid item xs={9} md={9}></Grid>
                                        <Grid item xs={3} md={3} style={{textAlign:"right",paddingRight:"5px"}}>
                                            <Button onClick={()=>selectAllPrivileges(allPrivileges)} variant="outlined" style={{color:"#000000BF"}}>Select All</Button>
                                        </Grid>
                                    </Grid>

                                    <Grid container style={{marginBottom:"50px"}}>
                                        {
                                            allPrivileges.filter((obj)=>{
                                                if(search===""){
                                                    return obj
                                                }else if(obj.name.toLowerCase().includes(search.trim().toLowerCase())){
                                                    return obj
                                                }
                                            }).map((privilege,index)=>{
                                                return(
                                                    <CustomAccordion key={index} elevation={0} TransitionProps={{ unmountOnExit: true }}  className={classes.highPrivilegeAccording}>
                                                        <AccordionSummary style={{height:"70px"}}>
                                                            <Grid container>
                                                                <Grid item xs={1} md={1}>
                                                                    <Checkbox style={{color:"rgba(24, 29, 51,0.63)"}} onChange={()=>selectPrivileges(privilege.id)} checked={currentPrivileges.filter(p=>p.id===privilege.id).length>0?true:false}/>
                                                                </Grid>
                                                                <Grid item xs={2} md={2}>
                                                                    <Tooltip title={privilege.name} placement="bottom-start" arrow>
                                                                        <Typography>{privilege.name.length>10?privilege.name.substring(0,10)+"...":privilege.name}</Typography>
                                                                    </Tooltip>
                                                                </Grid>
                                                                {
                                                                    allActions.map((action,j)=>{
                                                                        return(
                                                                            <Grid style={{textAlign:"center"}} key={j} item xs={1} md={1}>
                                                                                {privilege.actions.filter(a=>(a.action_name===action && a.selected===true)).length>0? 
                                                                                    <div className={classes.iconBackground}>
                                                                                        <Selected/>
                                                                                    </div>
                                                                                    :
                                                                                    <div className={classes.iconBackground}>
                                                                                        <NotSelected/>
                                                                                    </div>
                                                                                }
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{backgroundColor:"white", paddingBottom:"0px",padding:"0px"}}>
                                                            <AddNewRoleAccordingList allActions={allActions} onChange={(id)=>selectPrivileges(id)} data={privilege.child} opacity={0.25} padding={1} currentPrivileges={currentPrivileges}/>
                                                        </AccordionDetails>
                                                    </CustomAccordion>
                                                )
                                            })
                                        }
                                        <Grid item md={10}></Grid>
                                        <Grid item xs={6} md={1} style={{marginTop:"30px"}}>
                                            <Button variant='contained' className={classes.cancel} onClick={props.onClose}>Cancel</Button>
                                        </Grid>
                                        <Grid item xs={6} md={1} style={{marginTop:"30px"}}>
                                            <Button variant='contained' className={classes.add} onClick={submit}>{props.type==="edits"?"Update":"Add"}</Button>
                                        </Grid>
                                    </Grid>
                                </>
                                :<div></div>
                            }
                            
                        </Grid>
                    </Paper>
                </Grid>
            </Grid> 
            <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
        </div>
    )
}

const CustomAccordion = withStyles({
    root: {
      "&$expanded": {
        margin: "auto"
      }
    },
    expanded: {}
  })(Accordion);

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    addNew:{
        fontWeight:"bold"
    },
    paperContainer:{
        width:"95%",
        marginLeft:"20px",
        marginTop:"20px",
        marginBottom:"10px"
    },
    text:{
        color:"#707070",
        paddingBottom:"10px"
    },
    tableHeader:{
        backgroundColor:"#7C8E96",
        color:"black",
        marginTop:"15px",
        paddingTop:"10px",
        paddingBottom:"10px"
    },
    actionsBar:{
        backgroundColor:"rgba(124, 142, 150, 0.5)",
        paddingTop:"5px",
        paddingBottom:"5px",
    },
    highPrivilegeAccording:{
        backgroundColor:mainAccordingColor,
        width:"100%",
        marginBottom:"1px",
    },
    iconBackground:{
        width:"24px",
        height:"24px",
        borderRadius:"100%",
        backgroundColor:"white"
    },
    add:{
        backgroundColor:addButtonColor,
        color:"black",
        marginBottom:"10px",
        marginRight:"10px",
        width:"20%",
        boxShadow:"none",
        "&:hover":{
            boxShadow:"none",
        }
    },
    cancel:{
        backgroundColor:"white",
        color:addButtonColor,
        marginBottom:"10px",
        marginRight:"10px",
        width:"20%",
        boxShadow:"none",
        "&:hover":{
            boxShadow:"none",
        }
    }
  }),
);

const BackIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
    )
}
const ExpandIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#91949E"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>
    )
}

const Selected=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00CF37"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
    )
}

const NotSelected=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="red"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
    )
}