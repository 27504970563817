import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import {createStyles,Theme,makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    addHighPrivilegesAccording:{
        backgroundColor:"white",
        color:"black",
        marginLeft:"10px",
        marginRight:"10px",
        textTransform:"none",
        marginTop:"5px"
    },
    subIconButtonDiv:{
        backgroundColor:"white",
        width:"25px",
        height:"25px",
        borderRadius:"100%"
    },
    subPrivilegeAccording:{
        width:"100%",
        marginBottom:"1px"
    },
  }),
);
export function UserRoleAccordingList(props) {
    const classes = useStyles();
    return (
        <div style={{width:"100%"}}>
            {
                props.data.map((child,index)=>{
                    return(
                        <div key={index}>
                            <Accordion elevation={0} style={{backgroundColor:`rgba(124, 142, 150, ${props.opacity})`}} TransitionProps={{ unmountOnExit: true }}  className={classes.subPrivilegeAccording}>
                                <AccordionSummary style={{height:"65px"}}>
                                    <Grid container>
                                        <Grid item xs={1} md={1} style={{paddingTop:"20px",paddingLeft:`${props.padding}px`}}>
                                            <CheckBoxIcon/>
                                        </Grid>
                                        <Grid item xs={2} md={3} style={{textAlign:"left",paddingLeft:`${props.padding}px`}}>
                                            <Tooltip title={child.name} placement="bottom-start" arrow>
                                                <Typography style={{marginTop:"20px", marginBottom:"20px"}}>{child.name.length>15?child.name.substring(0,15)+"...":child.name}</Typography>
                                            </Tooltip>
                                        </Grid>
                                        {
                                            child.child.length===0 ?
                                                <Grid item xs={1} md={1}></Grid>
                                            :
                                            <Grid item xs={1} md={1} style={{paddingTop:"8px"}}>
                                                <IconButton><div className={classes.subIconButtonDiv}><ExpandIcon/></div></IconButton>
                                            </Grid>
                                        }
                                        {
                                            child.actions.map((action, i)=>{
                                                return(
                                                    <Grid item xs={1} md={2} key={i} style={{marginLeft:"10px"}}>
                                                        <Typography style={{color:"#4C4F50",marginTop:"20px"}}>{action.action_name}</Typography>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{backgroundColor:"white", padding:"0px"}}>
                                    <Grid container style={{width:"100%"}}>
                                        <UserRoleAccordingList data={child.child} opacity={props.opacity-0.1} padding={props.padding>21?props.padding:props.padding+20}/>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion> 
                        </div>
                    )
                })
            }
        </div>
    )
}
export const CheckBoxIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="rgba(24, 29, 51, 0.64)"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"/></svg>
    )
}
export const ExpandIcon = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
    )
}