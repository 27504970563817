import { Component, OnInit } from '@angular/core';
import { SideNavService } from '../services/sidenav.service';
import { MainService } from '../services/main.service';
import { ReferenceEntity, Role, User } from '../models';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FORM_TYPE, USER_ROLES, USER_TYPES } from '../enums';
import { toggleHideById } from '../utils';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  toggleHideById = toggleHideById;

  user: User;
  formType;
  userId;
  references: ReferenceEntity[] = [];
  referencesAll: ReferenceEntity[] = [];
  isSubmitted = false;
  referencesTemp;
  error;
  form;
  dropdownSettings;
  roles: Role[];


  constructor(private sideNavService: SideNavService, private mainService: MainService,
    private router: ActivatedRoute, private spinner: NgxSpinnerService, private toaster: ToastrService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.userId = params.id;
    });
    this.dropdownSettings = {
      singleSelection: false,
      search: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.getUser();
    this.getRoles();
    this.form = this.formBuilder.group({
      lastName: [null, [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z_ ]*')]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.required]],
      status: [null, Validators.compose([])],
      userRoleId: [[], [Validators.required]]
    });
  }

  getUser() {
    this.spinner.show();
    this.mainService.getUserById(this.userId).subscribe(res => {
      this.user = res.content;
      this.getReferencesAll();
      if (parseInt(this.user.userRoleId, 10) >= 10) {
        this.getReferences();
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
    },
      () => {
        this.spinner.hide();
      }
    );
  }

  getRoles() {
    this.spinner.show();
    this.mainService.getRoles()
      .subscribe(response => {
        this.roles = response.content;
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getReferences() {
    this.spinner.show();
    this.mainService.getReferencesOfUser(this.userId).subscribe(res => {
      this.references = res.content;
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
    },
      () => {
        this.spinner.hide();
      }
    );
  }

  getReferencesAll() {
    this.spinner.show();
    this.mainService.getReferencesForRole(this.user.userRoleId).subscribe(res => {
      this.referencesAll = res.content;
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
    },
      () => {
        this.spinner.hide();
      }
    );
  }

  addReference() {
    this.referencesTemp = Object.assign([], this.references);
    toggleHideById('addNew');
  }

  saveReferences() {

    const userUpdate = {id: this.user.id, references: this.references.map(ref => ref.id)};
    console.log(userUpdate);
    this.mainService.updateReferencesOfUser(userUpdate).subscribe(response => {
      this.toaster.success('User Created Successfully!');
      this.getUser();

      toggleHideById('addNew');
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.error = error.error.message;
      this.restoreOnFailure();
      this.toaster.error('Something Went Wrong!');
    }, () => {
      this.spinner.hide();
    });





  }



  editUser() {
    this.formType = FORM_TYPE.EDIT;
    this.isSubmitted = false;
    this.error = null;
    this.form.reset();
    const userToPatch = JSON.parse(JSON.stringify(this.user));
    this.form.patchValue(userToPatch);
    toggleHideById('editUser');


  }

  updateUser(value: any) {
    this.user.lastName = value.lastName;
    this.user.email = value.email;
    this.user.mobile = value.mobile;
    this.user.status = value.status;
    if (!this.user.references || this.user.references.length == 0) {
      this.user.userRoleId = value.userRoleId;
    } else {
      delete this.user.userRoleId;
    }
    this.mainService.updateUser(this.user).subscribe(response => {
      this.toaster.success('User Updated Successfully!');
      toggleHideById('editUser');
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    }, () => {
      this.getUser();
      this.spinner.hide();
    });
  }


  restoreOnFailure() {
    this.references = Object.assign([], this.referencesTemp);

  }

  getRoleName(roleId) {
    if (roleId != null) {
      if (!this.roles) {
        return roleId;
      }
      return this.roles.find(e => {
        return +e.id === +roleId;
      }).name;
    }
  }

  getTypeName(roleId) {
    return USER_TYPES[roleId];
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }
}
