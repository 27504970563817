import {SideNavService} from './../services/sidenav.service';
import {Component, OnInit} from '@angular/core';
import {toggleHideById} from '../utils';
import {FORM_TYPE} from '../enums';
import {FormBuilder, Validators} from '@angular/forms';
import {MainService} from '../services/main.service';
import {CorporateUser} from '../models';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-corporate-user',
  templateUrl: './corporate-user.component.html',
  styleUrls: ['./corporate-user.component.scss']
})
export class CorporateUserComponent implements OnInit {
  isSubmitted = false;
  cooperateList: CorporateUser[] = [];
  Accounts: CorporateUser[] = [];

  p=1;
  toggleHideById = toggleHideById;
  formType: FORM_TYPE;
  error;
  form;
  selectedUserId: string;
  selectedUserName: string;
  dropdownSettings;


  constructor(private sideNavService: SideNavService, private formBuilder: FormBuilder, private mainService: MainService,
              private toaster: ToastrService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {

    // this.dropdownSettings = {
    //   singleSelection: false,
    //   search: false,
    //   idField: 'id',
    //   textField: 'name',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: true
    // };

    this.getAllCorporates();
    this.getAllAccountUsers();
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      contactName: [null, [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z_ ]*')]],
      contactPhoneNumber: [null, [Validators.required]],
      contactEmail: [null, [Validators.required, Validators.email]],
      contactAddress:[null],
      accountId:[null, [Validators.required]],
    });
  }


  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  getAllAccountUsers() {
    this.spinner.show();
    this.mainService.getAccountUsers().subscribe(res => {
        this.Accounts = res.content;
      }, (error) => {
        console.log(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }


  getAllCorporates() {
    this.spinner.show();
    this.mainService.getCorporates().subscribe(res => {
        this.cooperateList = res.content;
      }, (error) => {
        console.log(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  addCorporate_Init() {
    this.formType = FORM_TYPE.ADD;
    this.isSubmitted = false;
    this.form.reset();
    this.error = null;
    toggleHideById('addNew');
  }

  editInit(corporate) {
    this.formType = FORM_TYPE.EDIT;
    this.isSubmitted = false;
    this.selectedUserId = corporate.id;
    this.form.reset();
    const userToPatch = JSON.parse(JSON.stringify(corporate));
    this.form.patchValue(userToPatch);
    this.error = null;
    toggleHideById('addNew');
  }


  submitCorporate(values) {

    this.isSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    if (this.formType === FORM_TYPE.ADD) {
      this.mainService.createCorporate(values).subscribe(response => {
        this.cooperateList.push(response.content);
        toggleHideById('addNew');
        this.toaster.success('Corporate Created Successfully!');
      }, error => {


        this.spinner.hide();
        this.error = error.error.message;
        this.toaster.error('Something Went Wrong!');

      }, () => {
        this.spinner.hide();
      });
    } else if (this.formType === FORM_TYPE.EDIT) {
      values.id = this.selectedUserId;
      this.mainService.updateCorporate(values, this.selectedUserId).subscribe(response => {
        toggleHideById('addNew');
        this.getAllCorporates();
        this.toaster.success('Corporate Updated Successfully!');
      }, error => {
        this.error = error.error.message;
        this.toaster.error('Something Went Wrong!');
      }, () => {
        this.spinner.hide();
        this.selectedUserId = '';
      });
    }
  }


  deleteCorporate() {
    this.spinner.show();
    this.mainService.deleteCorporate(this.selectedUserId).subscribe(response => {
      toggleHideById('deleteCorporate');
      this.getAllCorporates();
      this.toaster.success('Corporate Deleted Successfully!');
    }, error => {
      this.error = error.error.message;
      this.toaster.error('Something Went Wrong!');
    }, () => {
      this.spinner.hide();
      this.selectedUserId = '';
    });
  }
}
