import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeLayoutComponent} from './layouts/home-layout.component';
import {LoginLayoutComponent} from './layouts/login-layout.component';
import {LoginComponent} from './authentication/login/login.component';
import {HeaderComponent} from './layouts/header/header.component';
import {SideNavComponent} from './layouts/side-nav/side-nav.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {Toast, ToastrModule} from 'ngx-toastr';
import {AuthInterceptor} from './authentication/authInterceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { RolesComponent } from './roles/roles.component';
import { PrivilegesComponent } from './privileges/privileges.component';
import { UserActionsComponent } from './user-actions/user-actions.component';
import { UserViewComponent } from './user-view/user-view.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AccountComponent } from './account/account.component';
import { CorporateUserComponent } from './corporate-user/corporate-user.component';
import { PrivilagesComponentWrapper } from 'src/components/privilages/PrivilagesComponentWrapper';
import { RolesComponentWraper } from 'src/components/roles/RolesComponentWrapper';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    HeaderComponent,
    SideNavComponent,
    DashboardComponent,
    RolesComponent,
    PrivilegesComponent,
    UserActionsComponent,
    UserViewComponent,
    AccountComponent,
    CorporateUserComponent,
    PrivilagesComponentWrapper,
    RolesComponentWraper
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({preventDuplicates: true, positionClass: 'toast-bottom-right'}),
    FormsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
