import {Injectable, OnInit} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  isSideNavVisible;

  constructor() {
    this.isSideNavVisible = true || localStorage.getItem('IS_SIDE_NAV_VISIBLE') === 'true';
  }

  toggleSideNav() {
    this.isSideNavVisible = !this.isSideNavVisible;
    localStorage.setItem('IS_SIDE_NAV_VISIBLE', this.isSideNavVisible);
  }
}

