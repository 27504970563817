import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import {createStyles,Theme,makeStyles} from '@material-ui/core/styles';
import { SearchBar } from '../sub-components/SearchBar';
import { addButtonColor } from '../sub-components/colors/colors';
import { AddNewRole } from './add-new-role/AddNewRole';
import { DeleteIcon, EditIcon } from '../privilages/according-list/AccordingList';
import { UserRolePopUp } from './user-role-pop-up/UserRolePopUp';
import { environment } from 'src/environments/environment';
import { AlertBar } from '../sub-components/AlertBar';

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    addNewRole:{
        backgroundColor:addButtonColor,
        color:"black",
        marginLeft:"10px",
        marginRight:"10px",
        textTransform:"none",
    },
    tableHeader:{
        backgroundColor:"#7C8E96",
        color:"black",
        marginLeft:"15px",
        marginRight:"15px",
        marginTop:"15px",
        paddingTop:"10px",
        paddingBottom:"10px"
    },
    tableContent:{
        color:"black",
        marginLeft:"15px",
        marginRight:"15px",
        paddingTop:"10px",
        paddingBottom:"10px"
    },
    viewButton:{
        backgroundColor:"#7C8E9633",
    },
    text:{
        marginTop:"8px"
    }
  }),
);

export function RolesComponent(props) {
    const classes = useStyles()
    const [addNew,setAddNew] = useState(false)
    const [openPopUp,setOpenPopUp]=useState(false);
    const [addNewType,setAddNewType]=useState();
    const [search,setSearch]=useState("")

    const [userRoles,setUserRoles] = useState([])
    const [userRoleId,setUserRoleId] = useState()
    const [userName,setUserName] = useState()
    const [description,setDescriptions]=useState()

    //alert states
    const [alertOpen,setAlertOpen]=useState(false)
    const [severity,setSeverity]=useState("success")
    const [topic,setTopic]=useState("")
    const [message,setMessage]=useState("")

    const openAddNew=(type,id,name,description)=>{
        setUserRoleId(id)
        setUserName(name)
        setDescriptions(description)
        setAddNewType(type)
        setOpenPopUp(false)
        setAddNew(true);
    }
    const closeAddNew=()=>{
        setSearch("")
        getUserRoles()
        setAddNew(false)
    }

    const openPopUpWindow=(roleId,name,description)=>{
        setUserRoleId(roleId)
        setUserName(name)
        setDescriptions(description)
        setOpenPopUp(true);
    }
    const closePopUpWindow=()=>{
        setOpenPopUp(false);
    }

    const getUserRoles = ()=>{
        fetch(`${environment.host}/ums/super/user-role`,
        {
            method:"GET",
            headers:{ 'Content-Type': 'application/json' }
        }).then(response => response.json())
        .then(data =>{
            if(data.message==="Success"){
                setUserRoles(data.content);
            }
            else{
                setAlertOpen(true)
                setSeverity("error")
                setTopic("Error")
                setMessage(data.message)
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
            }
        })
    }
    const deleteRole=(id)=>{
        fetch(`${environment.host}/ums/super/user-role/${id}`,
        {
            method:"DELETE",
            headers:{ 'Content-Type': 'application/json' }
        }).then(response => response.json())
        .then(data =>{
            if(data.message==="Success"){
                getUserRoles()
                setAlertOpen(true)
                setSeverity("success")
                setTopic("Success")
                setMessage("User role deleted successfully")
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
            }
            else{
                setAlertOpen(true)
                setSeverity("error")
                setTopic("Error")
                setMessage(data.message)
                setTimeout(()=>{
                    setAlertOpen(false)
                },6000) 
            }
        })
    }

    useEffect(()=>{
        getUserRoles();
    },[])

    return (
        <div className={`main-react-view`}>
            {
                addNew===false?
                    <Grid container>
                        <Grid item xs={6} md={4}>
                        <SearchBar onChange={(e)=>setSearch(e.target.value)}/>
                        </Grid>
                        <Grid item  md={5}></Grid>
                        <Grid item xs={6} md={3}>
                            <Button variant='contained' onClick={()=>openAddNew("new-role","","","")}  className={classes.addNewRole}>New Role</Button>
                        </Grid>

                        <Grid container className={classes.tableHeader}>
                            <Grid item xs={1} md={1}>
                                <Typography style={{fontWeight:"bold"}}>Id</Typography>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Typography style={{fontWeight:"bold"}}>Role Name</Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Typography style={{fontWeight:"bold"}}>Description</Typography>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Typography style={{fontWeight:"bold"}}>Actions</Typography>
                            </Grid>
                        </Grid>

                        {
                            userRoles.filter((obj)=>{
                                if(search===""){
                                    return obj
                                }else if(obj.name.toLowerCase().includes(search.trim().toLowerCase())){
                                    return obj
                                }
                            }).map((role,index)=>{
                                return(
                                    <Grid container className={classes.tableContent} key={index} style={{backgroundColor:((index%2)===0 ||(index/2)===0)?"#F9F9F9":"white"}}>
                                        <Grid item xs={1} md={1} style={{textAlign:"left", paddingLeft:"25px"}}>
                                            <Tooltip title={role.id.toString()} placement="bottom-start" arrow>
                                                <Typography className={classes.text}>{role.id.toString().length>6?role.id.toString().substring(0,6)+"...":role.id}</Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2} md={2} style={{textAlign:"left", paddingLeft:"30px"}}>
                                            <Tooltip title={role.name} placement="bottom-start" arrow>
                                                <Typography className={classes.text}>{role.name.length>15?role.name.substring(0,15)+"...":role.name}</Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{textAlign:"left", paddingLeft:"35px"}}>
                                            <Tooltip title={role.description} placement="bottom-start" arrow>
                                                <Typography className={classes.text}>{role.description!==undefined && role.description.length>40?role.description.substring(0,40)+"...":role.description}</Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <IconButton onClick={()=>openPopUpWindow(role.id,role.name,role.description)}>
                                                <ViewIcon/>
                                            </IconButton>
                                            <IconButton onClick={()=>openAddNew("edits",role.id,role.name,role.description)} >
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={()=>deleteRole(role.id)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        
                    </Grid>
                :<AddNewRole onClose={()=>closeAddNew()} type={addNewType} userRoleId={userRoleId} userName={userName} description={description}/>
            }
           
            <UserRolePopUp open={openPopUp} description={description} userName={userName} userRoleId={userRoleId} onClose={()=>closePopUpWindow()} addNew={(type,id,name,description)=>openAddNew(type,id,name,description)}/>
            <AlertBar open={alertOpen} severity={severity} topic={topic} message={message}/>
        </div>
    )
}

const ViewIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20.25" height="15" viewBox="0 0 20.25 15">
        <g id="eye_1_" data-name="eye (1)" transform="translate(0.5 0.5)">
            <path id="Path_13136" data-name="Path 13136" d="M1,11s3.5-7,9.625-7,9.625,7,9.625,7-3.5,7-9.625,7S1,11,1,11Z" transform="translate(-1 -4)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <circle id="Ellipse_366" data-name="Ellipse 366" cx="3" cy="3" r="3" transform="translate(6.661 4)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        </g>
        </svg>
    )
}
