import {Component} from '@angular/core';

@Component({
  selector: 'app-login-layout',
  template: `
    <router-outlet></router-outlet>`,
  styles: [``]
})
export class LoginLayoutComponent {
  constructor() {
  }
}
