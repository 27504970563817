import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../services/sidenav.service';
import {MainService} from '../services/main.service';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, Validators} from '@angular/forms';
import {CorporateUser, User} from '../models';
import {toggleHideById} from '../utils';
import {FORM_TYPE} from '../enums';


@Component({
  selector: 'app-corporate-user',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  toggleHideById = toggleHideById;

  p=1;
  isSubmitted = false;
  error;
  form;
  dropdownSettings;
  userList: CorporateUser[] =[];
  selectedUserId: string;
  formType: FORM_TYPE;
  selectedUserName: string;

  constructor(private sideNavService: SideNavService, private mainService: MainService,
              private router: ActivatedRoute, private spinner: NgxSpinnerService, private toaster: ToastrService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    // this.router.params.subscribe(params => {
    //   this.userId = params.id;
    // });
    this.dropdownSettings = {
      singleSelection: false,
      search: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getAllAccountUsers();
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      contactName: [null, [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z_ ]*')]],
      contactPhoneNumber: [null, [Validators.required]],
      contactEmail: [null, [Validators.required, Validators.email]],
    });
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  getAllAccountUsers() {
    this.spinner.show();
    this.mainService.getAccountUsers().subscribe(res => {
        this.userList = res.content;
      }, (error) => {
        console.log(error);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  submitCorporateUser(values) {

    this.isSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    if (this.formType === FORM_TYPE.ADD) {
      this.mainService.createAccountUser(values).subscribe(response => {
        this.userList.push(response.content);
        toggleHideById('addNew');
        this.toaster.success('Corporate User Created Successfully!');
      }, error => {
        this.spinner.hide();
        this.error = error.error.message;
        this.toaster.error('Something Went Wrong!');
      }, () => {
        this.spinner.hide();
      });
    } else if (this.formType === FORM_TYPE.EDIT) {
       values.id = this.selectedUserId;
       this.mainService.updateAccountUser(values, this.selectedUserId).subscribe(response => {
        toggleHideById('addNew');
        this.getAllAccountUsers();
        this.toaster.success('Corporate User Updated Successfully!');
      }, error => {
        this.error = error.error.message;
        this.toaster.error('Something Went Wrong!');
      }, () => {
        this.spinner.hide();
        this.selectedUserId = '';
      });
    }
  }

  editInit(user) {
    this.formType = FORM_TYPE.EDIT;
    this.isSubmitted = false;
    this.selectedUserId = user.id;
    this.form.reset();
    const userToPatch = JSON.parse(JSON.stringify(user));
    this.form.patchValue(userToPatch);
    this.error = null;
    toggleHideById('addNew');
  }


  addCorporateUserInit() {
    this.formType = FORM_TYPE.ADD;
    this.isSubmitted = false;
    this.form.reset();
    this.error = null;
    toggleHideById('addNew');
  }


  deleteCorporateUser() {
    this.spinner.show();
    this.mainService.deleteAccountUser(this.selectedUserId).subscribe(response => {
      toggleHideById('deleteCorporate');
      this.getAllAccountUsers();
      this.toaster.success('Corporate User Deleted Successfully!');
    }, error => {
      this.error = error.error.message;
      this.toaster.error('Something Went Wrong!');
    }, () => {
      this.spinner.hide();
      this.selectedUserId = '';
    });
  }
}
