import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../services/sidenav.service';
import {ReferenceEntity, Role, User} from '../models';
import {FORM_TYPE, USER_ROLES, USER_TYPES} from '../enums';
import {NgxSpinnerService} from 'ngx-spinner';
import {MainService} from '../services/main.service';
import {toggleHideById} from '../utils';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, Validators} from '@angular/forms';
import {DataService} from '../services/data.service';
import {log} from 'util';
import {AuthService} from '../authentication/auth.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // imported methods reassigned for view
  toggleHideById = toggleHideById;

  users: User[] = [];
  usersFiltered: User[] = [];
  p = 1;
  pageSize = 10;
  dropdownSettings = {};
  dropdownSettingsforref = {};
  selectedUserTypes = [];
  formType;
  isSubmitted = false;
  isDataFetched = false;
  form;
  error;
  selectedUserId;
  selectedUserName;
  roles: Role[];
  rolesForRef: Role[];
  userReferenceALL: ReferenceEntity[] = [];
  searchTerm = '';
  currentSelectedUserId;
  hostUrl = '';
  selectedRoleId = null;
  references = [];


  constructor(private sideNavService: SideNavService, private spinner: NgxSpinnerService,
              private mainService: MainService, private toaster: ToastrService, private formBuilder: FormBuilder,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.getUsers();
    this.getRoles();

    this.dropdownSettings = this.dropdownSettings = {
      singleSelection: false,
      search: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsforref = {
      singleSelection: false,
      search: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


    this.form = this.formBuilder.group({
      lastName: [null, [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z_ ]*')]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.required]],
      userRoleId: [[], [Validators.required]],
      userreferences: [[], [Validators.required]]
    });
    this.getMetaData();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  onChanges(val) {
    this.selectedRoleId = val.target.value;
    // tslint:disable-next-line:triple-equals
    if (this.selectedRoleId < 10) {
      this.form.controls.userreferences.setValue([]);
      this.form.get('userreferences').clearValidators();
    } else {
      this.form.get('userreferences').setValidators([Validators.required]);
    }
    this.form.get('userreferences').updateValueAndValidity();
    if (this.selectedRoleId != null && this.selectedRoleId >= 10) {
      this.mainService.getReferencesForRole(this.selectedRoleId).subscribe(res => {
          this.userReferenceALL = res.content;
          // this.referencesStr = this.references.map(ref => ref.name);
          this.spinner.hide();
        }, (error) => {

          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
    }
  }

  getMetaData() {
    this.mainService.getMetaData()
      .subscribe(response => {
        this.hostUrl = response.content.configuration.configHost;
        this.dataService.updateData(response.content.configuration.configHost);

      }, error => {
      }, () => {
      });
  }

  getUsers() {
    this.spinner.show();
    this.mainService.getUsers()
      .subscribe(response => {
        this.users = response.content;
        this.usersFiltered = response.content;
        this.isDataFetched = true;
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getRoles() {
    this.spinner.show();
    const filterRole: string[] = ['super'];
    this.mainService.getRoles()
      .subscribe(response => {
        this.roles = response.content;
        this.rolesForRef = response.content.filter(word => !filterRole.includes(word.name));
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getRoleName(roleId) {
    if (!this.roles) {
      return roleId;
    }
    return this.roles.find(e => {
      return e.id === roleId;
    }).name;
  }

  getTypeName(roleId) {
    return USER_TYPES[roleId];
  }

  getUserTypes(): string[] {
    const lookup = [];
    if (!this.roles) {
      return [];
    }

    this.roles.forEach(obj => {
      lookup.push({item_id: obj.id, item_text: obj.name});
    });
    return lookup;
  }


  filterUsers() {
    const selTypes = [];
    this.selectedUserTypes.forEach(obj => selTypes.push(parseInt(obj.item_id, 10)));
    if (selTypes.length === 0) {
      this.clearAllFilters();
      return;
    }
    this.usersFiltered = this.users.filter(user => {
      return selTypes.includes(user.userRoleId);
    });
  }

  clearAllFilters() {
    this.usersFiltered = this.users;
  }

  addUserInit() {
    this.formType = FORM_TYPE.ADD;
    this.isSubmitted = false;
    this.form.reset();
    this.error = null;
    toggleHideById('addNew');
  }

  editUserInit(user) {
    this.formType = FORM_TYPE.EDIT;
    this.isSubmitted = false;
    this.error = null;
    this.form.reset();
    const userToPatch = JSON.parse(JSON.stringify(user));
    this.form.patchValue(userToPatch);
    toggleHideById('addNew');
  }

  createUser(value) {
    this.isSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    this.spinner.show();
    if (this.formType === FORM_TYPE.ADD) {
      // tslint:disable-next-line:triple-equals
      if (value.userRoleId >= 10) {
        this.references = [];
        this.references.push(value.userreferences);
        value.references = this.references;
      }
      this.dataService.share.subscribe(host => this.hostUrl = host);
      value.host = this.hostUrl;
      this.mainService.createUser(value).subscribe(response => {
        this.toaster.success('User Created Successfully!');
        toggleHideById('addNew');
        this.getUsers();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      }, () => {
        this.selectedRoleId = null;
        this.references = [];
        this.spinner.hide();
      });
    } else {
      this.mainService.updateUser(value).subscribe(response => {
        this.toaster.success('User Updated Successfully!');
        toggleHideById('addNew');
        this.getUsers();
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        if (error.status === 422) {
          this.error = error.error.message;
          this.toaster.error('Something Went Wrong!');
        } else {
          this.toaster.error('Oops... Something went wrong', 'Error!');
        }
      });
    }
  }

  deleteUser() {
    this.spinner.show();
    this.mainService.deleteUser(this.selectedUserId).subscribe(() => {
      this.toaster.success('User \"' + this.selectedUserName + '\" has been deleted successfully!');
      this.spinner.hide();
    }, (error) => {
      if (error.status === 422) {
        this.toaster.error(error.error.message, 'Error!');
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
      this.spinner.hide();
    }, () => {
      this.getUsers();
      this.spinner.hide();
    });
    toggleHideById('deleteUser');
  }

  getUsersFiltered() {
    return this.usersFiltered.filter(u => JSON.stringify(Object.values(u)).toLowerCase().includes(this.searchTerm.trim().toLowerCase()));
  }

//   resendRegistrationMail() {
//     toggleHideById('resendRegistrationMail');
//
//     console.log(this.selectedUserEmail);
//   }
// }


  resendRegistrationMail() {
    const selectedUser = this.users.find(item => {
      return item.id === this.currentSelectedUserId;
    });
    this.spinner.show();
    this.dataService.share.subscribe(host => this.hostUrl = host);
    this.mainService.resendRegistrationMail({
      host: this.hostUrl,
      email: selectedUser.email,
      mobile: selectedUser.mobile,
    }).subscribe(response => {
      this.spinner.hide();
      toggleHideById('resendRegistrationMail');
      this.toaster.success('A mail has been sent again. Please confirm within 24 hours', 'Success');
    }, error => {
      this.spinner.hide();
      if (error && error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin();
  }
}
