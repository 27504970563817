import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import {createStyles,Theme,makeStyles} from '@material-ui/core/styles';
import { useState } from 'react';

export function AddNewRoleAccordingList(props) {
    const classes = useStyles();
    const [show,setShow]=useState(-1)
    const openEdit=(i)=>{
        if(i===show){
            setShow(-1)
        }
        else{
            setShow(i)
        }
    }
    return (
        <div style={{width:"100%"}}>
        {
            props.data.map((child,index)=>{
                return(
                    <div key={index}>
                        <Accordion expanded={show===index} elevation={0} style={{backgroundColor:`rgba(124, 142, 150, ${props.opacity})`}} TransitionProps={{ unmountOnExit: true }}  className={classes.subPrivilegeAccording}>
                            <AccordionSummary style={{height:"70px"}}>
                                <Grid container>
                                    <Grid item xs={1} md={1} style={{paddingTop:"20px"}}>
                                        <Checkbox style={{color:"rgba(24, 29, 51,0.63)"}}
                                            onChange={()=>props.onChange(child.id)}
                                            checked={props.currentPrivileges.filter(p=>p.id===child.id).length>0?true:false}
                                            disabled={props.currentPrivileges.filter(p=>p.id==child.parentId).length>0?false:true}/>
                                    </Grid>
                                    <Grid item xs={2} md={1} style={{paddingLeft:`${props.padding}px`,textAlign:"left"}}>
                                        <Tooltip title={child.name} placement="bottom-start" arrow>
                                            <Typography style={{marginTop:"20px", marginBottom:"20px"}}>{child.name.length>10?child.name.substring(0,10)+"...":child.name}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    {
                                        child.child.length===0 ?
                                            <Grid item xs={1} md={1}></Grid>
                                        :
                                        <Grid item xs={1} md={1} style={{paddingTop:"8px"}}>
                                            <IconButton onClick={()=>openEdit(index)}><div className={classes.subIconButtonDiv}><ExpandIcon/></div></IconButton>
                                        </Grid>
                                    }
                                    {
                                        props.allActions.map((action,j)=>{
                                            return(
                                                <Grid style={{height:"65px",textAlign:"right",paddingTop:"25px",marginTop:"8px"}} key={j} item xs={1} md={1}>
                                                    {child.actions.filter(a=>(a.action_name===action && a.selected===true)).length>0?
                                                        <div className={classes.iconBackground}>
                                                            <Selected/>
                                                        </div>
                                                        :
                                                        <div className={classes.iconBackground}>
                                                            <NotSelected/>
                                                        </div>
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{backgroundColor:"white", padding:"0px"}}>
                                <Grid container style={{width:"100%"}}>
                                    <AddNewRoleAccordingList allActions={props.allActions} onChange={(id)=>props.onChange(id)} data={child.child} opacity={props.opacity-0.1} padding={props.padding>11?props.padding:props.padding+10} currentPrivileges={props.currentPrivileges}/>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
            })
        }
        </div>
    )
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    addHighPrivilegesAccording:{
        backgroundColor:"white",
        color:"black",
        marginLeft:"10px",
        marginRight:"10px",
        textTransform:"none",
        marginTop:"5px"
    },
    subIconButtonDiv:{
        backgroundColor:"white",
        width:"25px",
        height:"25px",
        borderRadius:"100%"
    },
    subPrivilegeAccording:{
        width:"100%",
        marginBottom:"1px"
    },
    iconBackground:{
        width:"24px",
        height:"24px",
        borderRadius:"100%",
        backgroundColor:"white"
    }
  }),
);
export const ExpandIcon = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
    )
}
const Selected=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00CF37"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
    )
}

const NotSelected=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="red"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
    )
}
