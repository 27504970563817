import * as React from 'react';
import { useState,useEffect } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, IconButton, Typography} from '@material-ui/core';
import {createStyles,Theme,makeStyles} from '@material-ui/core/styles';
import { addButtonColor, textColor } from '../../sub-components/colors/colors';
import { TextBar } from 'src/components/sub-components/TextBar';
import { environment } from 'src/environments/environment';

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    inputGridsLeft:{
        margin:'5px',
    },
    inputGridsRight:{
        marginLeft:'50px',
        margin:'5px',
        [themes.breakpoints.down('md')]: {
            marginLeft:"5px"
        },
    },
    text:{
        marginTop:"10px",
        marginBottom:"5px",
        color:textColor
    },
    iconButton:{
        backgroundColor:"white",
        "&:hover":{
            backgroundColor:"white"
        }
    },
    add:{
        backgroundColor:addButtonColor,
        color:"black",
        marginBottom:"10px",
        marginRight:"10px",
        width:"20%",
        boxShadow:"none",
        "&:hover":{
            boxShadow:"none",
        }
    },
    cancel:{
        backgroundColor:"white",
        color:addButtonColor,
        marginBottom:"10px",
        marginRight:"10px",
        width:"20%",
        boxShadow:"none",
        "&:hover":{
            boxShadow:"none",
        }
    }
  }),
);

export function PopUp(props) {
    const classes = useStyles()

    const [newActionGrid, setNewActionGrid] = useState(true)
    const [newActionText,setNewActionText] = useState()

    const [name,setName]=useState()
    const [id,setId]=useState()
    const [description,setDescription]=useState()
    const [actions,setActions] =useState([])

    const [idError, setIdError] = useState({error:false, errorMsg:""})
    const [nameError,setNameError] = useState({error:false, errorMsg:""})
    const [actionTextError,setActionTextError] = useState({error:false, errorMsg:""})

    //Function for read checkbox is selected or not
    const readActions=(e,index)=>{
        let oldActions=[...actions]
        oldActions[index]={action_name:e.target.name,selected:!oldActions[index].selected}
        setActions(oldActions)
        console.log(oldActions)
    }

    //Function for change visibility of add new action
    const addNewAction=()=>{
        setNewActionGrid(false)
    }

    //Function for read new action text
    const saveNewActionText=()=>{
        if(newActionText===undefined || newActionText===""){
            setActionTextError({error:true, errorMsg:"Action name cannot be emty"})
            return
        }
        let oldActions=[...actions]
        const currentIndex=oldActions.length;
        oldActions[currentIndex]={action_name:newActionText,selected:true}
        setActions(oldActions)
        setNewActionGrid(true)
    }
    //Function to read pivilege name
    const readName=(e)=>{
        setName(e.target.value)
        setNameError({error:false, errorMsg:""})
    }
    //Funcion to read new privilege id
    const readId=(e)=>{
        setId(e.target.value)
        setIdError({error:false, errorMsg:""})
    }
    //Function to read description
    const readDescription=(e)=>{
        setDescription(e.target.value)
    }

    //Save or update privilege
    const sendData=()=>{
        const data={
            id:id,
            name:name,
            description:description,
            actions:actions,
            parentId:props.parentId,
            child: [],
            childIds: []
        }
        if(props.type==="add-new"){
            if(data.name===undefined || data.name===""){
                setNameError({error:true, errorMsg:"Name is required"})
                return
            }
            if(data.id===undefined || data.id===""){
                setIdError({error:true, errorMsg:"Id is required"})
                return
            }
            apiRequest(data,"POST")
        }
        if(props.type==="add-high"){
            data.parentId="high-privilege"
            if(data.name===undefined || data.name===""){
                setNameError({error:true, errorMsg:"Name is required"})
                return
            }
            if(data.id===undefined || data.id===""){
                setIdError({error:true, errorMsg:"Id is required"})
                return
            }
            apiRequest(data,"POST")
        }
        if(props.type==="update"){
            if(data.name===undefined || data.name===""){
                data.name=props.defaultValues.name
            }
            if(data.id===undefined || data.id===""){
                data.id=props.defaultValues.id
            }
            if(data.description===undefined || data.description===""){
                data.description=props.defaultValues.description
            }
            apiRequest(data,"PUT")
        }
    }

    const apiRequest=(data,method)=>{
        let url=`${environment.host}/ums/super/user-privilege`
        if(method==="PUT"){
            url=url+"/"+props.defaultValues.id  //When changing the id request parameter id should be the old id
        }
        fetch(url,
            {
                method:method,
                headers:{ 'Content-Type': 'application/json' },
                body:JSON.stringify(data)
            }).then(response => response.json())
            .then(data =>{
              if(data.message==="Success"){
                setActions([])
                setId(undefined)
                setDescription(undefined)
                setName(undefined)
                props.onClose()
              }
              else{
                alert(data.message)
              }
            })
    }


    useEffect(()=>{
        if(props.type==="update"){
            setActions(props.defaultValues.actions)
        }
        else(
            setActions([])
        )
    },[props.open])

    return (
        <Dialog open={props.open}>
            <Grid container>
                <Grid item xs={10} md={10}>
                    <DialogTitle>{props.type==="update"?"Update": "Add New"} Privilege</DialogTitle>
                </Grid>
                <Grid item xs={2} md={2} style={{textAlign:"right"}}>
                    <IconButton onClick={()=>{
                        props.onClose() }}>
                        <CloseIcon/>
                    </IconButton>
                </Grid>
            </Grid>

            <Divider/>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={5} className={classes.inputGridsLeft}>
                        <Typography className={classes.text}>Enter Privilege</Typography>
                        <TextBar width="100%" defaultValue={props.type==="update"? props.defaultValues.name:""} type="text" error={nameError} onChange={(e)=>readName(e)}/>
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.inputGridsRight}>
                        <Typography className={classes.text}>Enter ID</Typography>
                        <TextBar width="100%" defaultValue={props.type==="update"? props.defaultValues.id:""} type="number" error={idError} onChange={(e)=>readId(e)}/>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.inputGridsLeft}>
                        <Typography className={classes.text}>Enter Description</Typography>
                        <TextBar width="100%" defaultValue={props.type==="update"? props.defaultValues.description:""} type="text" error={{error:false, errorMsg:""}} onChange={(e)=>readDescription(e)}/>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.inputGridsLeft}>
                        <Typography className={classes.text}>Select Actions</Typography>
                        <Grid container>
                            {
                                actions.map((action,index)=>{
                                    return(
                                            <Grid item xs={6} md={3} key={index} style={{marginTop:"10px"}}>
                                                <FormGroup row>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={action.selected} onChange={(e)=>readActions(e,index)} name={action.action_name} style={{color:"#666D73"}}/>}
                                                        label={action.action_name}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                    )
                                })
                            }
                            <Grid item xs={6} md={3} style={{marginTop:"3px"}}>
                                <IconButton className={classes.iconButton} onClick={addNewAction}>
                                    <AddIcon/>
                                </IconButton>
                            </Grid>

                            {
                                newActionGrid===false?
                                    <Grid container>
                                        <Grid item xs={6} md={6} style={{paddingRight:"5px"}}>
                                            <TextBar width="100%" error={actionTextError} onChange={(e)=>setNewActionText(e.target.value)}/>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Button onClick={saveNewActionText} variant='contained' className={classes.add}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                :<></>
                            }


                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.onClose()} variant='contained' className={classes.cancel}>
                    Cancel
                </Button>
                <Button onClick={sendData} variant='contained' className={classes.add}>
                    {props.type==="update"?"Update":"Add"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const CloseIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#666D73"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
    )
}

const AddIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#666D73"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-8-2h2v-4h4v-2h-4V7h-2v4H7v2h4z"/></svg>
    )
}
