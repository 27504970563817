import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../services/sidenav.service';
import {ActivatedRoute} from '@angular/router';
import {MainService} from '../services/main.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserAction} from '../models';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent implements OnInit {
  id: any;
  page = 0;
  actions: UserAction[] = [];
  error;
  isAllRetrieved = false;
  isFetchingData = false;
  pageno = 0;
  numberofelement = 0;

  constructor(private sideNavService: SideNavService, private route: ActivatedRoute,
              private mainService: MainService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.spinner.show();
    this.getActions(1);
  }

  // private getActions(offset: number) {
  //   this.spinner.show();
  //   this.mainService.getUserActionHistory(this.id, 10, offset).subscribe(response => {
  //     this.actions = response.content;
  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     this.error = error.error.statusDescription;
  //   });
  // }

  getActions(page: number, concat?) {
    this.isFetchingData = true;
    const order = 'DESC';
    this.mainService.getUserActionHistory(this.id, 20, page, order)
      .subscribe(response => {
        if (response.content.length < 10) {
          this.isAllRetrieved = true;
        }
        this.actions = this.actions.concat(response.content);
      }, error => {
        this.spinner.hide();
        this.isFetchingData = false;
        this.error = error.error.statusDescription;
      }, () => {
        this.spinner.hide();
        this.isFetchingData = false;
      });
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }

  loadMoreAtEnd(e): void {
    if (this.bottomReached() && !this.isAllRetrieved) {
      this.getActions(++this.page);
    }
  }

  bottomReached(): boolean {
    const offset = document.getElementById('main3').offsetHeight;
    const eleoffset = document.getElementById('tbrow').clientHeight;
    const scrolledHeight = document.getElementById('main3').scrollTop;
    return (offset + scrolledHeight + 1) >= document.getElementById('main3').scrollHeight;
  }


}
