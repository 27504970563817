import * as React from 'react';
import { InputAdornment, TextField} from '@material-ui/core';
import {createStyles,Theme,makeStyles, withStyles} from '@material-ui/core/styles';
import { searchBarBorderColor } from './colors/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inutField:{
        height:"1px",
    },
    endAdornment: {
        paddingRight: 1
    },
  }),
);

export function SearchBar(props) {
    const classes = useStyles()

    return (
        <CssTextField
            InputLabelProps={{shrink: false}}
            placeholder="search"
            InputProps={{
                classes:{
                    input: classes.inutField,
                },
                endAdornment: (
                <InputAdornment position="end">
                        <SearchIcon/>
                </InputAdornment>
                )
            }}
            onChange={props.onChange}
            variant="outlined"
        ></CssTextField>
    )
}

export const SearchIcon = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#42526E"><path d="M0 0h24v24H0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
    )
}

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: searchBarBorderColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: searchBarBorderColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: searchBarBorderColor,
        },
        '&:hover fieldset': {
          borderColor: searchBarBorderColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: searchBarBorderColor,
        },
      },
    },
  })(TextField);