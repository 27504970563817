import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MagmaResponse, MetaData, Privilege, ReferenceEntity, Role, User, UserAction } from '../models';
import { AuthService } from '../authentication/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http: HttpClient) {
  }

  changePassword(value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/change-password',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  // getUserActionHistory(userId): Observable<MagmaResponse<UserAction[]>> {
  //   return this.http.get<MagmaResponse<UserAction[]>>(
  //     environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + userId + '/history',
  //     {
  //       headers: new HttpHeaders(
  //         {
  //           'Content-Type': 'application/json',
  //           Authorization: 'Bearer ' + AuthService.getAccessToken()
  //         })
  //     }
  //   );
  // }

  getUserActionHistory(userId, offset, page, order): Observable<MagmaResponse<UserAction[]>> {
    return this.http.get<MagmaResponse<UserAction[]>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + userId + '/history?size=' + offset + '&page=' + page + '&order=' + order,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getUsers(): Observable<any> {
    return this.http.get<MagmaResponse<User[]>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user?filter=none',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getUserById(id): Observable<any> {
    return this.http.get<MagmaResponse<User>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + id,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getRoles(): Observable<any> {
    return this.http.get<MagmaResponse<Role[]>>(
      environment.host + '/ums/super/user-role',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createUserRole(userRole): Observable<any> {
    return this.http.post<MagmaResponse<any>>(
      environment.host + '/ums/super/user-role',
      userRole,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  updateUserRole(userRole): Observable<any> {
    return this.http.put<MagmaResponse<any>>(
      environment.host + '/ums/super/user-role/' + userRole.id,
      userRole,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  deleteUserRole(roleId): Observable<any> {
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/ums/super/user-role/' + roleId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getPrivileges(): Observable<any> {
    return this.http.get<MagmaResponse<Privilege[]>>(
      environment.host + '/ums/super/user-privilege',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createUserPrivilege(userPrivilege): Observable<any> {
    return this.http.post<MagmaResponse<any>>(
      environment.host + '/ums/super/user-privilege',
      userPrivilege,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  updateUserPrivilege(userPrivilege): Observable<any> {
    return this.http.put<MagmaResponse<any>>(
      environment.host + '/ums/super/user-privilege/' + userPrivilege.id,
      userPrivilege,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  deleteUserPrivilege(privilegeId): Observable<any> {
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/ums/super/user-privilege/' + privilegeId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createUser(user): Observable<any> {
    return this.http.post<MagmaResponse<any>>(
      environment.host + '/ums/super/user',
      user,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  updateUser(user): Observable<any> {
    return this.http.put<MagmaResponse<any>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + user.id,
      user,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  deleteUser(userId): Observable<any> {
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/ums/user/' + userId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getReferencesForRole(roleId) {
    return this.http.get<MagmaResponse<ReferenceEntity[]>>(
      environment.host + '/ums/super/user-role/' + roleId + '/references',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getReferencesOfUser(userId) {
    return this.http.get<MagmaResponse<ReferenceEntity[]>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + userId + '/references',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }


  getReferenceOfUserByName(userId, refName) {
    return this.http.get<MagmaResponse<ReferenceEntity[]>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + userId + '/reference/' + refName,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  updateReferencesOfUser(user): Observable<any> {
    return this.http.put<MagmaResponse<any>>(
      environment.host + '/ums/user/' + AuthService.getUserId() + '/system-user/' + user.id + '/references',
      user,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  resendRegistrationMail(value: any): Observable<any> {
    return this.http.post<any>(
      environment.host + '/resend-key',
      value,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
          }),
      }
    );
  }

  updateMetaData(metaData: any): Observable<MagmaResponse<MetaData>> {
    return this.http.put<any>(
      environment.host + '/user/' + AuthService.getUserId() + '/metadata',
      metaData,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getMetaData(): Observable<MagmaResponse<MetaData>> {
    return this.http.get<MagmaResponse<MetaData>>(
      environment.host + '/user/' + AuthService.getUserId() + '/metadata',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createAccountUser(account): Observable<any> {
    return this.http.post<MagmaResponse<any>>(
      environment.host + '/super/account',
      account,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }
  
  updateAccountUser(account, accountId): Observable<any> {
    return this.http.put<MagmaResponse<any>>(
      environment.host + '/super/account/' + accountId,
      account,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }
  getAccountUsers(): Observable<any> {
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/super/account',
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }
  deleteAccountUser(accountId): Observable<any> {
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/super/account/' + accountId,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

 

  updateCorporate(corporate,corporateID): Observable<any> {   ///user/{user}/corporate/{corporate}
    return this.http.put<MagmaResponse<any>>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateID,
      corporate,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  createCorporate(corporate): Observable<any> {
    return this.http.post<MagmaResponse<any>>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate',
      corporate,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

  getCorporates(): Observable<any> {  ///user/{user}/corporate
    return this.http.get<MagmaResponse<any>>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate' ,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }


  deleteCorporate(corporateID): Observable<any> {  ///user/{user}/corporate/{corporate}
    return this.http.delete<MagmaResponse<any>>(
      environment.host + '/user/' + AuthService.getUserId() + '/corporate/' + corporateID,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + AuthService.getAccessToken()
          })
      }
    );
  }

}

