import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

export function AlertBar(props) {
    

    return (
        <Snackbar open={props.open} autoHideDuration={6000} anchorOrigin={{ vertical:"bottom", horizontal:"right" }}>
            <Alert severity={props.severity}>
                <h3 style={{textAlign:"left",marginTop:"0px"}}>{props.topic}</h3>  
                <h3 style={{marginBottom:"0px"}}>{props.message}</h3>
            </Alert>
    </Snackbar>
    )
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}