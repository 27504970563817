import * as React from 'react';
import { TextField} from '@material-ui/core';
import {createStyles,Theme,makeStyles, withStyles} from '@material-ui/core/styles';
import { searchBarBorderColor } from './colors/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inutField:{
        height:"1px",
    },
    endAdornment: {
        paddingRight: 1
    },
    input:{
      width:"90%"
    }
  }),
);

export function TextBar(props) {
    const classes = useStyles()

    return (
        <CssTextField
            error={props.error.error}
            type={props.type}
            helperText={props.error.errorMsg}
            defaultValue={props.defaultValue}
            InputLabelProps={{shrink: false}}
            InputProps={{
                classes:{
                    input: classes.inutField,
                },
            }}
            style={{width:props.width}}
            onChange={props.onChange}
            variant="outlined"
        ></CssTextField>
    )
}

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: searchBarBorderColor,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: searchBarBorderColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: searchBarBorderColor,
        },
        '&:hover fieldset': {
          borderColor: searchBarBorderColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: searchBarBorderColor,
        },
      },
    },
  })(TextField);