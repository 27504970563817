export const USER_ROLES = {
  100: 'Corporate Admin',
  1000: 'Project Admin',
  2000: 'Maintenance L1',
  3000: 'Maintenance L2',
  4000: 'Maintenance L3',
  5000: 'Maintenance L4',
  6000: 'Maintenance L5',
  10000: 'Kit User'
};

export const USER_TYPES = {
  10: 'Account',
  100: 'Corporate',
  1000: 'Project',
  1001: 'Project',
  1002: 'Project',
  2000: 'Maintenance',
  3000: 'Maintenance',
  4000: 'Maintenance',
  5000: 'Maintenance',
  6000: 'Maintenance',
  10000: 'Kit'
};

export enum FORM_TYPE {
  ADD = 0,
  EDIT = 1,
}
