import {Component, OnInit} from '@angular/core';
import {SideNavService} from '../services/sidenav.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MainService} from '../services/main.service';
import {ToastrService} from 'ngx-toastr';
import {Privilege, Role} from '../models';
import {toggleHideById} from '../utils';
import {FORM_TYPE} from '../enums';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  // imported methods reassigned for view
  toggleHideById = toggleHideById;

  p=1;
  selectedRoleId;
  selectedRoleName;
  roles: Role[] = [];
  formType;
  error;
  form;
  isSubmitted = false;
  dropdownSettings;
  privileges: [];

  constructor(private sideNaveService: SideNavService, private spinner: NgxSpinnerService, private mainService: MainService, private toaster: ToastrService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.getRoles();
    this.getPrivileges();
    this.form = this.formBuilder.group({
      id: [null, [Validators.required]],
      name: [null, [Validators.required]],
      description: ['', []],
      userPrivileges: [[], []]
    });
    this.dropdownSettings = {
      singleSelection: false,
      search: false,
      idField: 'item_obj',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  isSideNavVisible() {
    return this.sideNaveService.isSideNavVisible;
  }

  getRoles() {
    this.spinner.show();
    this.mainService.getRoles()
      .subscribe(response => {
        this.roles = response.content;
        this.roles.sort((a, b) => {
          return a.id > b.id ? 1 : -1;
        });
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  deleteRole() {
    this.spinner.show();
    this.mainService.deleteUserRole(this.selectedRoleId)
      .subscribe(response => {
        this.toaster.success('Successfully Deleted!');
        this.spinner.hide();
      }, error => {
        this.toaster.error('Something Went Wrong!');
        this.spinner.hide();
      }, () => {
        this.getRoles();
        toggleHideById('deleteRole');
        this.spinner.hide();
      });
  }

  addRoleInit() {
    this.formType = FORM_TYPE.ADD;
    this.isSubmitted = false;
    this.form.reset();
    this.error = null;
    toggleHideById('addNew');
  }

  editRoleInit(role) {
    this.formType = FORM_TYPE.EDIT;
    this.isSubmitted = false;
    this.form.reset();
    const roleToPatch = JSON.parse(JSON.stringify(role));
    roleToPatch.userPrivileges = roleToPatch.userPrivileges.map(privilege => {
      return {item_obj: privilege, item_text: privilege.name};
    });
    this.form.patchValue(roleToPatch);
    this.error = null;
    toggleHideById('addNew');
  }

  createRole(value) {
    this.isSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    if (value.userPrivileges === null && !confirm('You are creating role without any privilege! want to continue?')) {
      return;
    }
    const valueToSubmit = JSON.parse(JSON.stringify(value));
    valueToSubmit.userPrivileges = valueToSubmit.userPrivileges.length !== null ? valueToSubmit.userPrivileges.map(privilege => privilege.item_obj) : [];
    if (this.formType === FORM_TYPE.ADD) {
      this.mainService.createUserRole(valueToSubmit).subscribe(response => {
        this.spinner.hide();
        toggleHideById('addNew');
        this.toaster.success('Role Created Successfully!');
      }, error => {
        this.spinner.hide();
        this.error = error.error.message;
        this.toaster.error('Something Went Wrong!');
      }, () => {
        this.getRoles();
        this.spinner.hide();
      });
    } else {
      console.log(valueToSubmit);

      this.mainService.updateUserRole(valueToSubmit).subscribe(response => {
        this.spinner.hide();
        toggleHideById('addNew');
        this.toaster.success('Role Updated Successfully!');
      }, error => {
        this.spinner.hide();
        this.error = error.error.message;
        this.toaster.error('Something Went Wrong!');
      }, () => {
        this.getRoles();
        this.spinner.hide();
      });
    }
  }

  getPrivileges() {
    this.spinner.show();
    this.mainService.getPrivileges()
      .subscribe(response => {
        this.privileges = response.content.map(privilege => {
          return {item_obj: privilege, item_text: privilege.name};
        });
      }, error => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }
}
