import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {LoginResponse} from '../../models';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginResponse: LoginResponse;
  loginError;
  form: FormGroup;
  isSubmitted;
  public showPassword: boolean;
  isDialog = false;

  constructor(private authService: AuthService, protected router: Router, private formBuilder: FormBuilder,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
    });

    if (window.location.host.toString().match('dialog')) {
      this.isDialog = true;
    }
  }

  signIn(auth) {
    this.isSubmitted = true;

    if (this.form.valid) {
      this.loginWithUserName(auth);
    }
  }

  loginWithUserName(auth) {
    auth.grant_type = 'password';
    auth.client_id = environment.client_id;
    auth.client_secret = environment.client_secret;
    this.spinner.show();
    this.authService.loginWithUserName(auth).subscribe(
      (response) => {
        this.spinner.hide();
        this.loginResponse = response;
        AuthService.loadCredential(this.loginResponse);
        if (response.role < 10) {
          this.router.navigate(['']);
        } else {
          this.loginError = {error: {}};
          this.loginError.error.error_description = 'You don\'t have permission';
        }
      },
      (error) => {
        console.log(this.loginError);
        this.loginError = error;
        this.spinner.hide();
      });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
