import {Component, OnInit, Output} from '@angular/core';
import {AuthService} from '../../authentication/auth.service';
import {MainService} from '../../services/main.service';
import { NavigationEnd, NavigationStart, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../custom-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {SideNavService} from '../../services/sidenav.service';
import {toggleHideById} from '../../utils';
import {MetaData, Privilege} from '../../models';
import {DataService} from '../../services/data.service';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // imported methods reassigned for view
  toggleHideById = toggleHideById;

  form: FormGroup;
  formConfig: FormGroup;
  isSubmitted = false;
  isSubmit = false;
  error;
  username;

  metaData: MetaData;
  configurations: any;
  headerName:string;

  constructor(private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
              private toaster: ToastrService, private mainService: MainService, private sideNavService: SideNavService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
      validator: CustomValidators.passwordMatchValidator
    });
    if(this.router.url==="/privileges"){
      this.headerName="Privileges Overview"
    }
    else if(this.router.url==="/roles"){
      this.headerName="Current User Roles"
    }
    else{
      this.headerName="" 
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((e: NavigationStart) => {
        if(e.url==="/privileges"){
          this.headerName="Privileges Overview"
        }
        else if(e.url==="/roles"){
          this.headerName="Current User Roles"
        }
        else{
          this.headerName="" 
        }
    });

    this.formConfig = this.formBuilder.group({
      configHost: [null, [Validators.required]]
    });
    this.getMetaData();
  }

  logout() {
    AuthService.signOut();
    this.router.navigate(['/login']);
  }

  updateHost(hostUrl) {
    this.dataService.updateData(hostUrl);
  }


  changeHost(value: any) {
    this.isSubmit = true;

    if (!this.formConfig.valid) {
      return;
    }
    this.spinner.show();
    const metaData = {configuration: value};
    this.mainService.updateMetaData(metaData).subscribe(response => {
      toggleHideById('changeconfiguration');
      this.metaData = metaData;
      this.updateHost(value.configHost);
      this.dataService.updateData(value.configHost);
      this.spinner.hide();
      this.toaster.success('Configuration updated successfully', 'Success');
    }, error => {
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.statusDescription;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });

  }

  getMetaData() {
    this.mainService.getMetaData()
      .subscribe(response => {
        this.metaData = response.content;
        this.updateHost(this.metaData.configuration ? this.metaData.configuration.configHost : '');
        this.formConfig.controls.configHost.setValue(this.metaData.configuration ? this.metaData.configuration.configHost : '');
      }, error => {
      }, () => {
      });
  }


  changePassword(value: any) {
    this.isSubmitted = true;

    if (!this.form.valid) {
      return;
    }

    this.spinner.show();
    this.error = null;
    value.username = AuthService.getUsername();
    this.mainService.changePassword(value).subscribe(response => {
      this.spinner.hide();
      toggleHideById('changePassword');
      this.toaster.success('Password changed successfully', 'Success');
    }, error => {
      console.log(error);
      this.spinner.hide();
      if (error.status === 422) {
        this.error = error.error.message;
      } else {
        this.toaster.error('Oops... Something went wrong', 'Error!');
      }
    });
  }

  menuStateChange() {
    let state;
    if (localStorage.getItem('MENU_STATE')) {
      state = localStorage.getItem('MENU_STATE');
    } else {
      state = 'OPEN';
    }

    if (state === 'OPEN') {
      localStorage.setItem('MENU_STATE', 'OPEN');
    } else {
      localStorage.setItem('MENU_STATE', 'CLOSE');
    }
  }

  toggleSideNav() {
    this.sideNavService.toggleSideNav();
  }

  isSideNavVisible() {
    return this.sideNavService.isSideNavVisible;
  }
}
