import * as React from 'react';
import { Accordion, AccordionSummary,AccordionDetails,Button, Grid, Typography, IconButton, Tooltip} from '@material-ui/core';
import {createStyles,Theme,makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    addHighPrivilegesAccording:{
        backgroundColor:"white",
        color:"black",
        marginLeft:"10px",
        marginRight:"10px",
        textTransform:"none",
        marginTop:"5px"
    },
    subIconButtonDiv:{
        backgroundColor:"white",
        width:"25px",
        height:"25px",
        borderRadius:"100%"
    },
    subPrivilegeAccording:{
        width:"100%",
        marginBottom:"1px"
    },
    AccordionSummary:{
        height:"55px",
        [themes.breakpoints.down('xs')]:{
            height:"150px",
        },
        [themes.breakpoints.between('xs','md')]:{
            height:"200px",
        }
    }
  }),
);

export const AccordingList=(props)=>{
    const classes = useStyles();
    const [hover,setHover]=React.useState(-1)
    const openPopUp=(e,i,type,defaultVal)=>{
        e.stopPropagation();
        props.onOpen(e,i,type,defaultVal)
    }

    const onHover=(id)=>{
        setHover(id)
    }
    const removeHover=()=>{
        setHover(-1)
    }
    return(
        <div style={{width:"100%"}}>
            {
                props.data.map((child,index)=>{
                    return(
                        <div key={index}>
                            <Accordion elevation={0} style={{backgroundColor:`rgba(124, 142, 150, ${props.opacity})`}} TransitionProps={{ unmountOnExit: true }}  className={classes.subPrivilegeAccording}>
                                <AccordionSummary className={classes.AccordionSummary}>
                                    <Grid container>
                                        <Grid item xs={2} md={2} style={{textAlign:"left",paddingLeft:`${props.padding}px`}}>
                                            <Tooltip title={child.name} placement="bottom-start" arrow>
                                                <Typography style={{marginTop:"12px"}}>{child.name.length>15?child.name.substring(0,15)+"...":child.name}</Typography>
                                            </Tooltip>
                                        </Grid>
                                        {
                                            child.child.length===0 ?
                                                <Grid item xs={1} md={1}></Grid>
                                            :
                                            <Grid item xs={1} md={1}>
                                                <IconButton><div className={classes.subIconButtonDiv}><ExpandIcon/></div></IconButton>
                                            </Grid>
                                        }

                                        <Grid item xs={2} md={2} style={{textAlign:"left"}}>
                                            <Tooltip title={child.id.toString()} placement="bottom-start" arrow>
                                                <Typography style={{marginTop:"12px"}}>{child.id.toString().length>6?child.id.toString().substring(0,6)+"...":child.id}</Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={3} md={4} style={{textAlign:"left"}}>
                                            <Tooltip title={child.description} placement="bottom-start" arrow>
                                                <Typography style={{marginTop:"12px"}}>{child.description!==undefined && child.description.length>40?child.description.substring(0,40)+"...":child.description}</Typography>
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={1} md={3} style={{marginTop:"5px",textAlign:"right"}}>
                                            <IconButton onClick={(e)=>openPopUp(e,child.parentId,"update",{name:child.name,id:child.id,description:child.description,actions:child.actions})}><EditIcon/></IconButton>
                                            <IconButton onClick={(id)=>props.onDelete(child.id)}><DeleteIcon/></IconButton>
                                            <Button
                                                onMouseEnter={()=>onHover(child.id)}
                                                onMouseLeave={()=>removeHover()}
                                                onClick={(e)=>openPopUp(e,child.id,"add-new",undefined)}
                                                className={classes.addHighPrivilegesAccording}
                                                style={{paddingLeft:hover===child.id?"10px":"0px",transition:"0.6s",backgroundColor:hover===child.id?"white":"white",minWidth:"24px",height:"24px"}}
                                                endIcon={<AddIcon/>}
                                                >{hover===child.id?"Add privilege":""}</Button>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>

                                <AccordionDetails style={{backgroundColor:"white", paddingRight:"0px",paddingLeft:"0px",paddingTop:"1px",paddingBottom:"0px"}}>
                                    <Grid container style={{width:"100%"}}>
                                        <AccordingList onDelete={(id)=>props.onDelete(id)} data={child.child} onOpen={(e,i,type,defaultVal)=>props.onOpen(e,i,type,defaultVal)} opacity={props.opacity-0.05} padding={props.padding>21?props.padding:props.padding+20}/>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const ExpandIcon = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
    )
}

export const EditIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15.121" viewBox="0 0 15 15.121">
            <g id="edit" transform="translate(0.5 0.5)">
                <path id="Path_1997" data-name="Path 1997" d="M8.5,4H3.444A1.444,1.444,0,0,0,2,5.444V15.556A1.444,1.444,0,0,0,3.444,17H13.556A1.444,1.444,0,0,0,15,15.556V10.5" transform="translate(-2 -2.879)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_1998" data-name="Path 1998" d="M15.436,2.319A1.5,1.5,0,1,1,17.56,4.443l-6.727,6.727L8,11.879l.708-2.833Z" transform="translate(-4 -1.879)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            </g>
        </svg>
    )
}

export const DeleteIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="15.931" viewBox="0 0 14.438 15.931">
            <g id="trash-2" transform="translate(-2.5 -1.5)">
                <path id="Path_13280" data-name="Path 13280" d="M3,6H16.438" transform="translate(0 -1.014)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Path_13281" data-name="Path 13281" d="M15.452,4.986V15.438a1.493,1.493,0,0,1-1.493,1.493H6.493A1.493,1.493,0,0,1,5,15.438V4.986m2.24,0V3.493A1.493,1.493,0,0,1,8.733,2h2.986a1.493,1.493,0,0,1,1.493,1.493V4.986" transform="translate(-0.507)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <line id="Line_503" data-name="Line 503" y2="4.594" transform="translate(8.743 8.891)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <line id="Line_504" data-name="Line 504" y2="4.594" transform="translate(11.04 8.891)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            </g>
        </svg>
    )
}

export const AddIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
    )
}
