import {style} from '@angular/animations';
import {Component} from '@angular/core';

@Component({
  selector: 'app-home-layout',
  template: `
    <app-header></app-header>
    <app-side-nav></app-side-nav>
    <router-outlet></router-outlet>`,
  styles: [``]
})
export class HomeLayoutComponent {

}
