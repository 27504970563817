import { CorporateUserComponent } from './corporate-user/corporate-user.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './authentication/login/login.component';
import {HomeLayoutComponent} from './layouts/home-layout.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginLayoutComponent} from './layouts/login-layout.component';
import {AuthGuard} from './auth.guard';
import {RolesComponent} from './roles/roles.component';
import {PrivilegesComponent} from './privileges/privileges.component';
import {UserActionsComponent} from './user-actions/user-actions.component';
import {UserViewComponent} from './user-view/user-view.component';
import {AccountComponent} from './account/account.component';
import { PrivilagesComponentWrapper } from 'src/components/privilages/PrivilagesComponentWrapper';
import { RolesComponentWraper } from 'src/components/roles/RolesComponentWrapper';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component: DashboardComponent},
      {path: 'roles', component: RolesComponentWraper},
      {path: 'privileges', component: PrivilagesComponentWrapper},
      {path: 'accounts', component: AccountComponent},
      {path: 'corporates', component:CorporateUserComponent},
      {path: 'user-view/:id', component: UserViewComponent},
      {path: 'user-actions/:id', component: UserActionsComponent},
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent}
    ]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
